/**
 * 所有网络请求
 */
// form 请求头
const HEAD_FORM = {
  header: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
}
const apis = jc => ({
  /**
   * @用户登录及账号密码
   */
  // 登录
  login: data => jc.post('/login/CrmLogin', data),
  // 员工详情&&个人信息
  getStaffDetails: data => jc.post('/worker/WorkerDetail', data),
  /**
   * @数据导入导出
   */
  // 解析excel
  // exceExport: data => jc.post('/excel/importToExportUser', data, HEAD_FORM),
  exceExport: data => jc.post('/excel/importToOrderExcel', data, HEAD_FORM),
  // 数据导入，客户导入
  // importOneUser: data => jc.post('/excel/excelImportOneUser', data)
  importOneUser: data => jc.post('/excel/importToOrder', data)

})

export default apis;