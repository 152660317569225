import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken } from '../utils/token'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 项目id
    project: localStorage.getItem('PROJECT'),
    token: getToken()
  },
  mutations: {
    SET_TOKEN (state, value) {
      state.token = value;
      setToken(value);
    },
    SET_PROJECT (state, newVal) {
      state.project = newVal;
    }
  },
  actions: {
  }
})
