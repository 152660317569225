<template>
  <div class='login'>
    <div class="from">
      <el-card class="box-card">
        <h2 class='title'>登录</h2>
        <el-form ref="form"
                 :model="form">
          <el-form-item label="用户名">
            <el-input v-model="form.phoneNum"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input show-password
                      v-model="form.password"></el-input>
          </el-form-item>
        </el-form>
        <el-button size='max'
                   class='el-button'
                   @click='sut'>登录</el-button>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    form: {
      phoneNum: '',
      password: ''
    }
  }),
  methods: {
    async sut () {
      const data = await this.$api.login(this.form);
      if (data.code == 200) {
        this.$store.commit('SET_TOKEN', data.data.token)
        this.$router.push('/');
      } else {
        this.$message('密码或用户名不正确')
      }
    }
  }
}
</script>


<style scoped>
.from {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 382px;
}
.el-button {
  width: 100%;
}
</style>