<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created () {
    this.$api.getStaffDetails()
  }
}
</script>


<style>
</style>
