/**
 * 二次封装uview中的网络请求
 */
import apis from './http.apis'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

// 请求拦截器
const REQUEST = [
  config => {
    // 为每个域名创建一个实例
    const baseURL = {
      // 测试&盛世云
      ssy: 'https://bd.php.huaxinestates.com/api',
      cs: 'https://bd.php.huaxinestates.com/api',
      // 华鑫
      hx: 'https://hx.api.1024xxkj.com/api',
      // 锦程
      jc: 'https://api.jc.1024kj.top/api',
      // jc: 'https://customer.php.huaxinestates.com/api',
      // jc: 'https://bd.php.huaxinestates.com/api',
      // 国邦盛世
      gy: 'https://api.guoyikonggu.com/api',
      gbss: 'https://api.guoyikonggu.com/api',
      // 丰石
      zq: 'https://customer.php.huaxinestates.com/api',
      fs: 'https://customer.php.huaxinestates.com/api'
      // zq: 'https://bd.php.huaxinestates.com/api',
      // fs: 'https://bd.php.huaxinestates.com/api'
    }[store.state.project] || 'https://bd.php.huaxinestates.com/api';
    config.url = baseURL + config.url;
    config.headers.common['channel'] = 1;
    console.log(config)
    if (store.state.token) config.headers.common['token'] = store.state.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
]
// 响应拦截器
const RESPONSE = [
  response => {
    if (response.data.code == 400) {
      router.push({ name: 'Login' });
      store.commit('user/SET_TOKEN')
    }
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
]

const install = Vue => {
  const jc = axios.create({
    timeout: 15000
  })
  jc.interceptors.request.use(...REQUEST)
  jc.interceptors.response.use(...RESPONSE)
  // 将http.api.js中所有接口挂载到$api上
  Vue.prototype.$api = apis(jc);
}

export default {
  install
}
