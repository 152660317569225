<template>
  <div>
    <!-- 文件上传 -->
    <div class="up-box"
         v-if='showUpload'>

      <el-upload drag
                 :limit='limitNum'
                 :auto-upload="false"
                 accept=".xlsx"
                 :action='UploadUrl()'
                 :before-upload="beforeUploadFile"
                 :on-change="fileChange"
                 :on-exceed="exceedFile"
                 :on-success="handleSuccess"
                 :on-error="handleError"
                 :file-list="fileList">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template slot="tip">
          <div class="el-upload__tip">只能上传xlsx文件，且不超过10M</div>
          <a href="https://9421gbl2gb.k.topthink.com/@5q2gzv7peg/shujudaoru.html"
             target="_blank">使用教程</a>
        </template>
      </el-upload>
      <br />
      <el-button size="small"
                 type="primary"
                 :disabled='fileList.length === 0'
                 @click="uploadFile">立即解析</el-button>
    </div>
    <!-- 表格展示区域 -->
    <div class="table-box"
         v-if='showTable'>
      <el-button @click='reImport'>重新导入</el-button>
      <el-table :data="listData"
                border
                style="width: 100%">
        <el-table-column v-for='(value,key) in tableTh'
                         :key='value'
                         :prop='key'
                         :label='value'>
        </el-table-column>
        <el-table-column fixed='right'
                         width='150px'
                         label='上传状态'>
          <template slot-scope="scope">
            <el-tag effect="dark"
                    type="info"
                    disable-transitions
                    v-if='dataSource[scope.$index].code == 1'>待上传</el-tag>
            <el-tag effect="dark"
                    type=""
                    disable-transitions
                    v-else-if='dataSource[scope.$index].code == 2'><i class='el-icon-loading'></i>上传中</el-tag>
            <el-alert effect="dark"
                      type="success"
                      :closable='false'
                      show-icon
                      v-else-if='dataSource[scope.$index].code == 3'>上传成功</el-alert>
            <template v-else>
              <el-alert :title="dataSource[scope.$index].message"
                        type="error"
                        :closable='false'
                        show-icon>
              </el-alert>
              <el-button size='mini'
                         plain
                         @click='caller(scope.$index)'><i class='el-icon-refresh-right'></i>重新上传</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="jindu">
        <el-progress class='el-progress'
                     text-inside
                     :stroke-width="26"
                     :percentage="percentage"
                     v-if='!oneUp'></el-progress>
        <el-button type="primary"
                   v-if='oneUp'
                   @click='upTable'>开始上传</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      limitNum: 1,  // 上传excell时，同时允许上传的最大数
      fileList: [],   // excel文件列表
      showUpload: true,
      showTable: false,
      // 储存列表
      tableTh: {},//表头
      listData: [],// 用来显示的表
      dataSource: [],// 记录上传状态
      oneUp: true
    }
  },
  methods: {
    // [立即解析]
    async uploadFile () {
      if (!this.vailToken()) return;
      const loading = this.$loading({
        lock: true,
        text: '正在解析',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // 将xlsx文件传至后台进行解析
      let form = new FormData();
      form.append('excel', this.fileList[0]);
      const data = await this.$api.exceExport(form);
      if (data.code != 200) return this.$alert(data.msg);
      if (data.data.length < 2) {
        return this.$alert('原因：该表格缺少表头或只有表头没有内容', '解析失败', {
          callback: () => { }
        })
      }
      // 索引0是表头行，取下来。内容赋值给listData
      this.tableTh = data.data.shift();
      this.listData = data.data;
      this.dataSource = data.data.map(() => ({
        code: 1
      }));

      // 隐藏上传区域，展示表格区域
      this.showUpload = false;
      this.showTable = true;
      loading.close();
    },

    // [导入至数据库]
    upTable () {
      if (!this.vailToken()) return;
      this.dataSource = this.listData.map(() => ({
        code: 1
      }));
      this.oneUp = false;
      // 启动上传
      this.caller(0, true);
    },
    // 上传
    async caller (index, isConti) {
      this.$set(this.dataSource, index, {
        code: 2
      });
      // 上传本行数据
      // const reqConfig = {
      //   url: '/excel/excelImportOne',
      //   method: 'post',
      //   data: this.objTurnForm(this.listData[index]),
      //   headers: {
      //     token: this.token
      //   }
      // }
      console.log(this.listData[index])
      const data = await this.$api.importOneUser(this.listData[index])
      console.log(data)
      this.$set(this.dataSource, index, data.code == 200 ? { code: 3 } : { code: 4, message: data.msg });
      // 如果是连续上传并且listData还没有完全上传完毕则继续上传下一条
      if (isConti && (index != this.listData.length - 1)) {
        this.caller(++index, isConti);
      }
    },
    // 验证身份
    vailToken () {
      if (!this.token) {
        this.$alert('该操作需要登录，您未登录或登录状态已失效', '未登录', {
          closeOnClickModal: false,
          callback: () => {
            this.$router.push('/login')
          }
        })
      }
      return this.token;
    },
    out () {
      localStorage.removeItem('token');
      this.$router.push('/login')
    },
    // 重新导入
    reImport () {
      // 隐藏上传区域，展示表格区域
      this.showUpload = true;
      this.showTable = false;
      this.oneUp = true;
      this.fileList = [];
    },
    // 文件超出个数限制时的钩子
    exceedFile () {
      this.$message.warning(`只能选择 ${this.limitNum} 个文件`);
    },
    // 文件状态改变时的钩子
    fileChange (file) {
      console.log(file.raw);
      this.fileList.push(file.raw);
      console.log(this.fileList);
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile (file) {
      console.log('before upload');
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== 'xlsx') {
        this.$message.warning('只能上传后缀是.xlsx的文件');
      }
      if (size > 10) {
        this.$message.warning('文件大小不得超过10M');
      }
    },
    // 文件上传成功时的钩子
    handleSuccess (res, file, fileList) {
      console.log(res, file, fileList)
      this.$message.success('文件上传成功');
    },
    // 文件上传失败时的钩子
    handleError (err, file, fileList) {
      console.log(err, file, fileList);
      this.$message.error('文件上传失败');
    },
    UploadUrl: function () {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return ""
    },
    objTurnForm (data) {
      let form = new FormData();
      for (let i in data) form.append(i, data[i]);
      return form;
    }
  },
  computed: {
    percentage () {
      const succNum = this.dataSource.filter(i => i.code == 3).length;
      return (succNum / this.listData.length) * 100
    },
    ...mapState([
      'token'
    ])
  }
}
</script>

<style scoped>
.jindu {
  padding-top: 30px;
  display: flex;
  align-items: center;
}
.jindu .el-progress {
  flex: 1;
  margin-right: 30px;
}
</style>

