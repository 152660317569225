import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import LinkFailure from '../views/LinkFailure.vue'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/linkFailure',
    name: 'LinkFailure',
    component: LinkFailure
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('路由拦截')
  // 将路由中的token保存
  if (to.query.token) {
    store.commit('SET_TOKEN', to.query.token);
  }
  // 进入数据统计必须要有项目参数
  if (to.query.proje_key) {
    store.commit('SET_PROJECT', to.query.proje_key);
    localStorage.setItem('PROJECT', to.query.proje_key)
  } else if (!store.state.project && to.name != 'LinkFailure') {
    next({ path: '/linkFailure' });
    return;
  }
  // 根据query.xm来更改baseURL
  // if (to.query.xm) {
  //   const baseURL = {
  //     // 华鑫线上
  //     'hx-shang': 'https://hx.api.1024xxkj.com/api',
  //     // 锦程线上
  //     'jc-shang': 'https://api.jc.1024kj.top/api',
  //     // 线下
  //     'hx-xia': 'https://bd.php.huaxinestates.com/api',
  //     'jc-xia': 'https://bd.php.huaxinestates.com/api'
  //   }[to.query.xm];
  //   if (store.state.baseURL != baseURL) {
  //     store.commit('param/GET_BASE_URL', baseURL)
  //   }
  // }
  // 去除掉路由（url）中的一些参数
  if (to.query.token && !to.query.embed) {
    let qCopy = { ...to.query };
    delete qCopy.token
    const lo = router.resolve({
      path: to.path,
      query: qCopy
    });
    location.href = location.origin + lo.href;
  }

  if (to.name == 'LinkFailure') next();
  if (to.name !== 'Login' && !store.state.token) {
    next({
      path: '/login'
    })
  } else next()
})

export default router
